
import http from "@/http";
import { Breadcrumb, Key } from "@/interfaces";
import Vue from "vue";

import MyEditor from "@/components/key/MyEditor.vue";

export interface DataType {
  key?: Key;
}

export default Vue.extend({
  components: {
    MyEditor,
  },
  data(): DataType {
    return {
      key: undefined,
    };
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      if (this.key == null) {
        return [];
      }

      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/",
        },
        {
          text: "建物",
          disabled: false,
          to: "/buildings",
        },
        {
          text: `${this.key.building.name}`,
          disabled: false,
          to: `/building/${this.key.building.id}`,
        },
        {
          text: "共用部カギ",
          disabled: false,
          to: `/building/${this.key.building.id}/key`,
        },
        {
          text: `#${this.key.id} ${this.key.name}`,
          disabled: false,
          to: `/key/${this.key.id}`,
        },
        {
          text: "編集",
          disabled: true,
        },
      ];
    },
  },
  async mounted() {
    this.key = undefined;

    const { id } = this.$route.params;

    const url = `keys/${id}`;

    const { data } = await http.get<Key>(url);

    this.key = data;
  },
  methods: {
    async save(e: Key) {
      if (this.key == null) {
        return;
      }

      const url = `/keys/${this.key?.id}`;

      await http.put(url, {
        ...e,
      });

      await this.$router.push(`/key/${this.key.id}`);
    },
  },
});
