
import { Key } from "@/interfaces";
import Vue, { PropType } from "vue";

export interface DataType {
  name: string;
  note?: string;
  valid: boolean;
}

export default Vue.extend({
  props: {
    keyData: {
      type: Object as PropType<Key>,
      default: undefined,
    },
  },
  data(): DataType {
    return {
      name: "",
      note: "",
      valid: false,
    };
  },
  computed: {
    rules() {
      return {
        required: [(v: string) => !!v || "必須です"],
      };
    },
  },
  mounted() {
    if (this.keyData == null) {
      return;
    }

    this.name = this.keyData.name;
    this.note = this.keyData.note;
  },
  methods: {
    async submit() {
      this.$emit("save", {
        name: this.name,
        note: this.note,
      });
    },
  },
});
